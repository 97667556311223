import React, { useEffect, useState } from "react";
import AnimatedText from 'react-animated-text-content';
import axios from 'axios';
import './daily.css';
function Daily() {
    let [pericope, setPericope] = useState('');
    let [loading, setLoading] = useState(null);
    var now = new Date();
    let dayofmonth = now.getDate()
    let year = now.getFullYear();
    const month = now.toLocaleString('default', { month: 'long' });
    let date = month + ' ' + dayofmonth + ', ' + year
    useEffect(() => {
        setLoading(true);
        var now = new Date();
        var start = new Date(now.getFullYear(), 0, 0);
        var diff = (now - start) + ((start.getTimezoneOffset() - now.getTimezoneOffset()) * 60 * 1000);
        var oneDay = 1000 * 60 * 60 * 24;
        var day = Math.floor(diff / oneDay);
        axios.get('https://beholdchriststorage.blob.core.windows.net/scripture/jesuspericopelist.json')
            .then(response => {
                setPericope(response.data[day].html)
                setLoading(false);
            });
        document.title = 'Behold Christ - Daily';
    }, []);
    return (
        <>
            {!loading ?
                (<>
                    <p style={{ textAlign: "center" }}>{date}</p>
                    <section dangerouslySetInnerHTML={{ __html: pericope }}>

                    </section>
                    <section style={{ marginTop: 25, borderTop: 5, borderTopStyle: "solid", borderTopColor: "black" }}>
                        <p style={{ textAlign: "center", fontStyle: "italic" }}>"Behold Christ Daily"<br></br>"Daily Pericopes with Red Letters"</p>
                        <p className="copy" style={{ textAlign: "center" }}>Scripture quotations taken from the (LSB®) Legacy Standard Bible®, Copyright © 2021 by The Lockman Foundation. Used by permission. All rights reserved. Managed in partnership with Three Sixteen Publishing Inc. LSBible.org and 316publishing.com.</p>
                    </section>
                </>)
                :
                (
                    <>
                        <AnimatedText
                            type="chars" // animate words or chars
                            animation={{
                                x: '0',
                                y: '200',
                                scale: 1.1,
                                ease: 'ease-in-out',
                            }}
                            animationType="lights"
                            interval={0.06}
                            duration={1}
                            tag="h1"
                            className="animated-paragraph"
                            includeWhiteSpaces
                            threshold={0.1}
                            rootMargin="20%"
                        >
                            BEHOLD CHRIST DAILY
                        </AnimatedText>
                        <section className="spinner"></section>
                        <p className="introtext" style={{ marginTop: 90, textAlign: "center", color: '#d13d3d' }}>"Therefore everyone who hears these words of Mine and does them, may be compared to a wise man who built his house on the rock."</p>
                    </>)
            }
        </>
    )
}

export default Daily;